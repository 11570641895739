import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

// import Seo from '../../../components/seo'
// import Layout from '../../../components/layout'
// import Hero from '../../../components/hero'
import CategoryPreview from '../../../components/category-preview'

class CategoryIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulCategory.nodes')
    return (
    //   <Layout location={this.props.location}>
    //     <Seo title="Categories" />
    //     <Hero title="Categories hello ryan" />
        <CategoryPreview posts={posts} />
    //   </Layout>
    )
  }
}

export default CategoryIndex

export const pageQuery = graphql`
  query CategoryIndexQuery {
    allContentfulCategory {
      nodes {
        name
        slug
      }
    }
  }
`
